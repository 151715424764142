<template>
  <jaya-connection-layout text="Connexion via un réseau social en cours...">
  </jaya-connection-layout>
</template>

<script>
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";

export default {
  components: {
    JayaConnectionLayout,
  },

  mounted() {
    if (this.$route.query.state !== localStorage.getItem("socialState")) {
      localStorage.removeItem("socialState")
      this.$notify({
        "title": "Erreur",
        "text": "Votre tentative de connexion a échoué en raison d'un problème de sécurité",
        "color": "danger",
        "time": 4000
      })
      this.$router.push({name: "login"})
    } else {
      this.$loading(true)
      localStorage.removeItem("socialState")
      let payload = {
        code: this.$route.query.code,
        provider: this.$route.query.provider
      }
      this.$store.dispatch('account/socialLogin', payload)
          .then((response) => {
            if (response.status_code === 201) {
              this.$notify({
                title: "Bienvenue",
                text: "Bienvenue au jardin",
                color: "primary",
                time: 4000
              })
            } else {
              this.$notify({
                title: "Connecté",
                text: "Bon retour parmi nous",
                color: "primary",
                time: 4000
              })
            }
            this.$loading(false)
            if (process.env.VUE_APP_USE_TOKEN) {
              this.$fireLoginDone();
            }
            this.$router.push({name: "jayaccueil"})
          })
          .catch(() => {
            this.$loading(false)
            this.$notify({
              title: "Erreur",
              text: "Une erreur est survenue à la connexion, veuillez réessayer plus tard",
              color: "danger",
              time: 4000
            })
            this.$router.push({name: "login"})
          })
    }
  }
}
</script>
